// import * as React from "react"
import Hero from "../components/hero-001"

// export default function Main() {
  
//     <Hero
//     subheading="Welcome to"
//     heading="Seed You Soon"
//     text="We are a dynamic collective of enthusiasts commited to helping you switching on your brightest ventures."
//     image={{
//       src: "/images/sys-neon-2.jpeg",
//       alt: "Hero image",
//     }}
//     buttons={undefined} />

// }

import * as React from "react"

export default function IndexPage() {
  return (
    // <div variant="container" textAlign="center" py="20">
    //   <h1 variant="heading.h1">
    //     Welcome to <span color="primary">Reflexjs</span>
    //   </h1>
    //   <p fontSize="xl" my="4">
    //     Get started by editing{" "}
    //     <code variant="text.code">src/pages/index.tsx</code>
    //   </p>
    //   <a href="https://reflexjs.org/docs" variant="button.primary">
    //     Read the docs
    //   </a>
    // </div>
    <Hero
        subheading="Welcome to"
        heading="Seed You Soon"
        text="We are a dynamic collective of enthusiasts commited to helping you switching on your brightest ventures!"
        image={{
          src: "/images/sys-neon-2.jpeg",
          alt: "Hero image",
        }}
        buttons={undefined} />
  )
}